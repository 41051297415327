import React from 'react';
import { withRouter } from 'react-router-dom';
import { findContent } from '../services/api';
import ZoomPage from '../components/ZoomPage';
import ContentSinglePage from '../components/ContentSinglePage';
import ContentPost from '../components/ContentPost/ContentPost';
import LivePlayer from '../components/LivePlayer';
import YTPlaylist from './YTPlaylist';
import ContentSite from '../components/ContentSite';
import FileDetail from "../components/FileDetail";
// import Library from "../components/Library";
import moment from 'moment';
import { recordTracking } from '../services/api';
import ContentAudio from '../components/ContentAudio/ContentAudio';
import LoaderWhite from '../components/LoaderWhite';
import { compose } from 'redux';
import { updateContentType } from '../actions';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer/dist/modern';
import { getInactiveTime } from '../services/utils';
import MosaicJourneyStepList from './MosaicJourneyStepList';
import JourneyStepNavigation from './JourneyStepNavigation';
import ImpactJourneyStepNavigation from './ImpactJourneyStepNavigation';

import _ from 'lodash';
import ContentPdfViewer from '../components/ContentPdfViewer';
moment.locale('pt-br');
class ContentSelector extends React.Component {
	constructor(props) {
		super(props);
		this.handleOnActive = this.handleOnActive.bind(this);
		this.handleOnIdle = this.handleOnIdle.bind(this);
		this._isMounted = true;
		this.setState = this.setState.bind(this);
		this.idleTimer = null;
		this.intervalID = 0;
		this.state = {
			isLoading: true,
			contentData: [],
			idContentItem: 0,
			idContentType: 0,
			timeRefresh: 5 * 60 * 1000,
			ignoreRecordTracking: false,
			isFrame: false,
			timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
			isYoutubePlayer: false,
		};
	}

	handleOnActive(event) {
		this.setState({ timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss') });
		this.setState({ ignoreRecordTracking: false });
	}

	handleOnIdle(event) {
		if (!this.checkElementMedia('audio') && !this.checkElementMedia('video')) {
			this._recordTracking();
			this.setState({ ignoreRecordTracking: true });
			return;
		}
	}

	checkElementMedia(type) {
		let isPlaying = false;
		try {
			const media = this.state.isFrame
				? [
						...window.document
							.getElementsByTagName('iframe')[0]
							?.contentDocument.getElementsByTagName(type),
				  ]
				: [...window.document.getElementsByTagName(type)];
			media.forEach((element) => {
				if (!element.paused) {
					this.resetTime();
					isPlaying = true;
				}
			});
		} catch (e) {
			isPlaying = false;
		}
		return isPlaying;
	}

	async loadContent(id) {
		this.setState({ isLoading: true });
		const result = await findContent(id);
		if (this._isMounted) {
			try {
				if (result.data.success === false) {
					this.setState({ isLoading: false });
					return;
				}
				this.setState({ isLoading: false, contentData: result.data.content });
			} catch (err) {
				this.setState({ isLoading: false });
			}
		}
	}
	resetTime() {
		if (this.idleTimer) {
			this.idleTimer.reset();
		}
	}

	removeEventListenerFrame() {
		if (
			window.document.getElementsByTagName('iframe').length &&
			window.document.getElementsByTagName('iframe')[0].contentDocument
		) {
			const frame =
				window.document.getElementsByTagName('iframe')[0].contentDocument;

			frame.removeEventListener(
				'scroll',
				_.debounce(() => {
					this.idleTimer.reset();
				}, 500)
			);
			frame.removeEventListener(
				'mousemove',
				_.debounce(() => {
					this.idleTimer.reset();
				}, 500)
			);
			frame.removeEventListener(
				'mouseup',
				_.debounce(() => {
					this.idleTimer.reset();
				}, 500)
			);
		}
	}
	checkActiveFrame() {
		let frame =
			window.document.getElementsByTagName('iframe')[0].contentDocument?.activeElement;
		if (!frame.getAttribute('listener')) {
			this.addEventListenerFrame();
		}
	}
	addEventListenerFrame() {
		if (
			window.document.getElementsByTagName('iframe').length &&
			window.document.getElementsByTagName('iframe')[0].contentDocument
		) {
			const frame =
				window.document.getElementsByTagName('iframe')[0].contentDocument;
			this.setState({ isFrame: true });
			//utilizado para identificar se ocorreu navegação dentro do frame
			if (frame.activeElement) {
				frame.activeElement.setAttribute('listener', true);
			}

			frame.addEventListener(
				'scroll',
				_.debounce((event) => {
					this.resetTime();
				}, 500)
			);
			frame.addEventListener(
				'mousemove',
				_.debounce((event) => {
					this.resetTime();
				}, 500)
			);

			frame.addEventListener(
				'mouseup',
				_.debounce((event) => {
					this.resetTime();
				}, 500)
			);
		} else {
			window.document.getElementsByTagName('iframe');
		}
	}

	selectContent(item) {
		let pathParams = new URLSearchParams(this.props.location.search);
		let parentSection = pathParams.get('idSection') || this.props?.idSection;
		switch (item?.idContentType) {
			case 1:
				return (
					<ContentAudio
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 2:
				return (
					<LivePlayer
						isVideo
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 3:
				return (
					<ContentPost
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 4:
				return (
					<ContentSite
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 5:
				return (
					<ContentSinglePage
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 6:
				return (
					<LivePlayer
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 7:
				return (
					<YTPlaylist
						data={item}
						key={item.idContentType}
						idSection={parentSection}
						setState={this.setState}
						idContentItem={item.idContentItem}
					/>
				);
			case 8:
				return (
					<ZoomPage
						data={item}
						key={item.idContentType}
						idSection={parentSection}
					/>
				);
			case 15:
				return <MosaicJourneyStepList data={item} idSection={parentSection}/>;
			case 16:
				return <JourneyStepNavigation data={item} idSection={parentSection}/>;
      case 18:
        return (
            <FileDetail
                data={item}
                idSection={parentSection}
            /> 
        )
			case 22:
				return <MosaicJourneyStepList data={item} idSection={parentSection}/>;
			case 23:
				return <ImpactJourneyStepNavigation data={item} idSection={parentSection}/>;
      case 25:
        return <ContentPdfViewer data={item} idSection={parentSection}/>
			// case 13:
			//     return <Library data={item} key={index} idSection={parentSection}/>
			default:
				return <div>No content type</div>;
		}
	}
	_recordTracking() {
		let pathParams = new URLSearchParams(this.props.location.search);
		let parentSection = pathParams.get('idSection') || this.props?.idSection;
		const ignoreRecordByIdContentType = [1, 2, 6];
		//existe tracking interno em audio e video
		 if(!ignoreRecordByIdContentType.includes(this.state.idContentType)){
			let timeExecuted = 0;
			//caso seja youtube list, verifica no localstorage o tempo de execução do video e adiciona no timeExecuted
			//e limpa o localstorage currentTimeYouTubeList
			if (this.state.idContentType === 7 && !this.state.isYoutubePlayer) {
				let storage = localStorage.getItem('currentTimeYouTubeList');
				const objCurrentTime = storage ? JSON.parse(storage) : false;
				if (
					objCurrentTime &&
					parseInt(objCurrentTime.contentItem) === this.state.idContentItem
				) {
					timeExecuted = objCurrentTime.currentTime;
					localStorage.removeItem('currentTimeYouTubeList');
				}
			}
			// id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
			recordTracking(
				this.state.timeStart,
				this.state.idContentItem,
				1,
				parentSection,
				timeExecuted
			)
			.then((res) => {})
			.catch((err) => {
				this.setState({ isLoading: false });
			});

		}
	}
	componentDidMount() {
		const minutes = getInactiveTime();

		this.setState({ isYoutubePlayer: false, timeRefresh: minutes });

		this.loadContent(this.props.match.params.id || this.props.idContentItem);
		this.state.timeStart = moment().utc().format('YYYY-MM-DD HH:mm:ss');
		window.addEventListener('beforeunload', (ev) => {
			this._recordTracking();
		});
		this.resetTime();
		//delay para capturar o frame
		setTimeout(() => {
			this.addEventListenerFrame();
			if (this.state.isFrame) {
				this.intervalID = window.setInterval(
					this.checkActiveFrame.bind(this),
					5000
				);
			}
		}, 2000);
		this.props.updateContent(this.state.idContentType);
	}
	componentWillUnmount() {
		this.props.updateContent(null);
		if (!this.state.ignoreRecordTracking) {
			this._recordTracking();
		}
		this.removeEventListenerFrame();
		this.setState({});
		clearInterval(this.intervalID);
		this._isMounted = false;
	}

	render() {
		return (
			<div>
				{this.state.isLoading ? (
					<LoaderWhite />
				) : (
          <div>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={this.state.timeRefresh}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              debounce={250}
            />
            {this.selectContent(this.state.contentData?.[0])}
          </div>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	updateContent: (item) => dispatch(updateContentType(item)),
});

export default compose(
	withRouter,
	connect(null, mapDispatchToProps)
)(ContentSelector);
